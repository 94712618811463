import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure
} from '@chakra-ui/react'
import {DeleteIcon} from '@chakra-ui/icons'
import React from "react";
import GenerateToken from './GenerateToken';
import useManageToken from "../hooks/useManageToken";
import {formatIsoDateString} from "../utils/formatString";
import {Skeleton} from "./Skeleton";

const Dashboard = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef = React.useRef<HTMLButtonElement>(null);
    const {tokenData, isTokenLoading, deleteTokenMutation, isLoadingDelete} = useManageToken();

    const handleDeleteToken = async (event: any) => {
        event.preventDefault();
        await deleteTokenMutation();
        onClose();
    };

    if (isTokenLoading) return <Skeleton/>

    return (
        <Box p={3}>
            <GenerateToken/>
            <Box borderWidth='1px' borderRadius='lg' p={2}>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Token Name</Th>
                                <Th>Created At</Th>
                                <Th>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {tokenData?.result.token ?
                                (<Tr>

                                    <Td>{tokenData?.result.token.name}</Td>
                                    <Td>{formatIsoDateString(tokenData?.result.token.created_at)}</Td>
                                    <Td><Button variant='solid' colorScheme='white' onClick={onOpen}>
                                        <DeleteIcon w={8} h={8} color="red.500"/></Button></Td>
                                </Tr>) : (
                                    <Tr>
                                        <Td colSpan={3}>
                                            No Token data found.
                                        </Td>
                                    </Tr>
                                )
                            }

                        </Tbody>
                    </Table>
                </TableContainer>
                <AlertDialog
                    leastDestructiveRef={cancelRef}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete Token
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Are you sure? You can't undo this action afterwards.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button onClick={onClose} ref={cancelRef}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme='red'
                                    onClick={handleDeleteToken}
                                    ml={3}
                                    isDisabled={isLoadingDelete}
                                    loadingText={'Deleting Token'}
                                    isLoading={isLoadingDelete}
                                >
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Box>
        </Box>
    );
};

export default Dashboard;