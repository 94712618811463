import {Button, Center, Heading, Text, VStack} from "@chakra-ui/react";
import {FcGoogle} from "react-icons/fc";
import React from "react";
import useAuthentication from "../hooks/useAuthentication";

const NoAuthHome = () => {
    const { loginWithGoogle} = useAuthentication();

    return (
        <VStack alignItems="flex-start" spacing="10px">
            <Heading
                fontWeight={600}
                lineHeight={'110%'}>
                Welcome!{' '}
                <Text as={'span'} color={'red.400'}>Stranger</Text>
            </Heading>
            <Button w={'full'} variant={'outline'} leftIcon={<FcGoogle/>} onClick={loginWithGoogle}>
                <Center>
                    <Text>Sign in with Google</Text>
                </Center>
            </Button>
        </VStack>
    )

}
export default NoAuthHome;