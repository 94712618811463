import {Badge, Button, Center, Heading, Text, VStack} from "@chakra-ui/react";
import React from "react";
import useAuthentication from "../hooks/useAuthentication";
import {ArrowForwardIcon} from "@chakra-ui/icons";

const AuthHome = () => {
    const { user, logout} = useAuthentication();

    return (
        <VStack alignItems="flex-start" spacing="10px">
            <Heading
                fontWeight={600}
                lineHeight={'110%'}>
                Welcome!{' '}
                <Text as={'span'} color={'green.400'}>{user?.displayName}</Text>
            </Heading>
            <Badge textTransform={'lowercase'}>{user?.email}</Badge>
            <Button w={'full'} rightIcon={<ArrowForwardIcon/>} onClick={logout} colorScheme='purple'
                    variant='solid'>
                <Center>
                    <Text>Logout</Text>
                </Center>
            </Button>
        </VStack>
    )

}
export default AuthHome;