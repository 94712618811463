import useGoogleAuth from "./useAuthentication";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {QueryKeys} from "./useManageToken";
import {useClipboard, useDisclosure, useToast} from "@chakra-ui/react";
import {useState} from "react";

const useGenerateToken = () => {
    const queryClient = useQueryClient();
    const {user} = useGoogleAuth();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast();
    const [tokenData, setTokenData] = useState(null);
    const [tokenName, setTokenName] = useState<string>('');
    const {onCopy, hasCopied} = useClipboard('');

    const clearData = () => {
        setTokenName('');
        setTokenData(null);

    }

    const generateTokenMutation = async (token_name: string) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({email: user?.email, token_name}),
        };

        const response = await fetch(
            "https://api.geniee.io/users/token",
            requestOptions
        );
        const data = await response.json();
        if (response.ok) {
            toast({
                title: "Token Generated",
                description: "Your token has been successfully generated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setTokenData(data.result.token);
        } else {
            toast({
                title: "OOPS!! Error",
                description: "There was an error generating token. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const {mutate, isSuccess, isLoading} = useMutation(generateTokenMutation, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.fetchToken]);
        },
    });

    const disableGenerateToken = !!tokenData || tokenName.trim().length===0;

    return  {
        mutate:mutate,
        isLoading,
        isSuccess,
        tokenData,
        setTokenData,
        tokenName,
        setTokenName,
        onCopy,
        hasCopied,
        isOpen,
        onOpen,
        onClose,
        clearData,
        disableGenerateToken
    }
}

export default useGenerateToken;