import {FIREBASE_CONFIG} from "../constants/Auth";
import {useEffect, useState} from "react";
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';

const useAuthentication = () => {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    firebase.initializeApp(FIREBASE_CONFIG);
    const auth = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();

    useEffect(() => {
        // Check if user authentication credentials are stored in local storage
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
            setIsAuthenticated(true);
        }
        setIsLoading(false);
        // Set up an authentication state observer to update the user state
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in
                setUser(user);
                localStorage.setItem('user', JSON.stringify(user));
                setIsAuthenticated(true)
            } else {
                // User is signed out
                setUser(null);
                localStorage.removeItem('user');
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        });

        // Clean up the observer on unmount
        return () => unsubscribe();
    }, [auth]);


    const loginWithGoogle = async () => {
        setIsLoading(true)
        try {
            const result = await auth.signInWithPopup(provider);
            setUser(result.user);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false)
    };

    const logout = async () => {
        setIsLoading(true)
        try {
            await auth.signOut();
            setIsLoading(false)
        } catch (error) {
            console.error(error);
            setIsLoading(false)
        }
        setIsLoading(false);
        setIsAuthenticated(false);
    };
    return {
        user,
        loginWithGoogle,
        logout,
        isLoading,
        isAuthenticated
    }

}
export default useAuthentication;
