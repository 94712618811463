import useAuthentication from "./useAuthentication";
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import {useToast} from "@chakra-ui/react";

export interface TokenResponse {
    error: string;
    result: TokenResult;
    status_code: number;
}

export interface TokenResult {
    message: string;
    token: Token;
}

export interface Token {
    author: string
    created_at: string
    id: string;
    is_valid: boolean
    name: string;
    updated_at?: string;
}

interface GetTokenParams {
    email: string | null | undefined;
}

export enum QueryKeys {
    fetchToken = 'fetchToken'
}

const useManageToken = () => {
    const {user, isLoading} = useAuthentication();
    const queryClient = useQueryClient();

    const toast = useToast();

    const userEmail: string | null | undefined = user?.email;

    const fetchToken = async ({email}: GetTokenParams): Promise<TokenResponse> => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const response = await fetch(`https://api.geniee.io/users/${email}/token`, requestOptions);
        if (!response.ok) {
            throw new Error('Failed to fetch token');
        }
        return await response.json();
    };

    const {
        data: tokenData,
        isLoading: isTokenLoading
    } = useQuery([QueryKeys.fetchToken], () => fetchToken({email: userEmail}),
        {
            enabled: !isLoading, // Only fetch token when isLoading is false
        }
    );

    const deleteTokenRequest = async () => {
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        };
        const response = await fetch(`https://api.geniee.io/users/${userEmail}/token`, requestOptions);
        if (response.ok) {
            toast({
                title: "Token Deleted",
                description: "Your token has been deleted successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "OOPS!! Error",
                description: "There was an error deleting token. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        return response.json();
    };
    const {mutate: deleteTokenMutation, status: deleteTokenStatus, isLoading:isLoadingDelete, isSuccess} =
        useMutation(deleteTokenRequest, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.fetchToken]);
        },
    });

    return {tokenData, isTokenLoading, deleteTokenMutation, deleteTokenStatus, isLoadingDelete, isSuccess};
}
export default useManageToken;