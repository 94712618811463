import {
    Container,
    GridItem, Flex, Avatar, keyframes, Box, SkeletonCircle, SkeletonText
} from '@chakra-ui/react';
import useAuthentication from "../hooks/useAuthentication";
import React from "react";
import AuthHome from "./AuthHome";
import NoAuthHome from "./NoAuthHome";

const Home = () => {
    const size = '96px';
    const color = 'teal';

    const pulseRing = keyframes`
      0% {
        transform: scale(0.33);
      }
      40%,
      50% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    `;

    const {user, isLoading} = useAuthentication();

    return (
        <Container maxW={'container.lg'} py={12}>
            <Flex gap={4}>
                <GridItem>
                    <Flex>
                        <Box
                            as="div"
                            position="relative"
                            w={size}
                            h={size}
                            _before={{
                                content: "''",
                                position: 'relative',
                                display: 'block',
                                width: '300%',
                                height: '300%',
                                boxSizing: 'border-box',
                                marginLeft: '-100%',
                                marginTop: '-100%',
                                borderRadius: '50%',
                                bgColor: color,
                                animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
                            }}>
                            <Avatar
                                src={user?.photoURL || ''}
                                size="full"
                                position="absolute"
                                top={0}
                            />
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem>
                    {isLoading ? (
                        <Box padding='6' boxShadow='lg' bg='white' width={'500px'}>
                            <SkeletonCircle size='10'/>
                            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2'/>
                        </Box>
                    ) : ((user && !isLoading) ? (
                            <AuthHome/>
                        ) : (
                            <NoAuthHome/>
                        )
                    )}

                </GridItem>
            </Flex>
        </Container>
    );
};

export default Home;