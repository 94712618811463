import React from 'react';
import {
    ChakraProvider,
    theme,
} from "@chakra-ui/react"
import {Route, Routes, Navigate} from 'react-router-dom';
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Sidebar from './pages/Sidebar';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {PrivateRoute} from "./pages/PrivateRoute";

export const App = () => {
    const queryClient = new QueryClient()
    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <Sidebar>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route
                                path="/dashboard"
                                element={
                                    <PrivateRoute>
                                        <Dashboard/>
                                    </PrivateRoute>
                                }
                            />
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                </Sidebar>
            </QueryClientProvider>
        </ChakraProvider>
    )
}
