import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    Box,
    FormLabel,
    Badge,
    Text
} from '@chakra-ui/react'
import React from "react";
import useGenerateToken from "../hooks/useGenerateToken";
import {CopyIcon} from '@chakra-ui/icons'

const GenerateToken = () => {
    const initialRef = React.useRef(null)
    const {mutate, isLoading, tokenData,disableGenerateToken,clearData, setTokenName, tokenName, onCopy, hasCopied, isOpen, onOpen, onClose} = useGenerateToken();

    const handleTokenSubmit = async (event: any) => {
        event.preventDefault();
        mutate(tokenName.trim());
    }

    return (
        <Box p={2}>
            <Button onClick={onOpen}>Generate Token</Button>
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={() => {
                    clearData();
                    onClose()
                }}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Generate New Token</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Token name</FormLabel>
                            <Input
                                ref={initialRef}
                                placeholder='Enter Token Name'
                                value={tokenName}
                                onChange={(e) => setTokenName(e.target.value)}
                            />
                        </FormControl>
                        {tokenData  && <Box pt={3} pb={3}>
                            <Text fontSize='sm' my='2'>
                                Please copy the generated token below
                            </Text>
                            <Text fontSize='sm' my='2' color='red'>
                                Token will be shown only once. Please save it somewhere safe.
                            </Text>
                            <Box p='3' borderWidth='1px' rounded='md'>
                                <code>{tokenData}</code> <CopyIcon onClick={onCopy}
                                                                           sx={{cursor: 'pointer', marginLeft: '5px'}}/>
                                <Badge variant='subtle' colorScheme='green'
                                       onClick={onCopy}> {hasCopied ? "Copied" : ""} </Badge>
                            </Box>
                        </Box>}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme='blue'
                            mr={3}
                            onClick={handleTokenSubmit}
                            isLoading={isLoading}
                            isDisabled={disableGenerateToken}
                            loadingText='Generating Token'>
                            Generate Token
                        </Button>
                        <Button
                            onClick={() => {
                                clearData();
                                onClose()
                            }}
                        >Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
export default GenerateToken;